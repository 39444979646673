<template>
  <div>
    <HistoryFilter v-if="$can('filter', 'ailments')" :loading="loading" @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="History" iconTable="GitBranchIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(show_details)="row">
          <b-form-checkbox v-model="row.detailsShowing" class="vs-checkbox-con" @change="row.toggleDetails">
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-card>
            <CardCash :CashStats="{
              income: row.item.income,
              current_balance: row.item.current_balance,
              current_descriptive: row.item.current_descriptive,
            }" />
            <b-table small bordered responsive="sm" class="mt-2" show-empty empty-text="No matching records found"
              :fields="fields" :items="row.item.cashes">
              <template #cell(show_details)="row">
                <b-form-checkbox v-model="row.detailsShowing" class="vs-checkbox-con" @change="row.toggleDetails">
                </b-form-checkbox>
              </template>
              <template #row-details="row">
                <b-table small bordered responsive="sm" class="mt-2" show-empty empty-text="No matching records found"
                  :fields="fieldsCash" :items="row.item.movements">
                  <template #cell(name)="data">
                    <b-link :to="{
                      name: 'recipe-patient',
                      params: { id: data.item.movementable_id },
                    }">{{ data.item.event.name }}</b-link>
                  </template>
                  <template #cell(value)="data">
                    <b-badge pill :variant="getColor(data.item.event.movement_types_id)">
                      <i :class="getIcon(data.item.event.movement_types_id)" class="mr-2 text-white"></i>

                      {{ "$ " + data.value }}
                    </b-badge>
                  </template>
                </b-table>
              </template>
              <template #cell(cash_statuses)="data">
                <b-badge pill :variant="data.item.cash_statuses_id === 1 ? 'success' : 'danger'
                  ">
                  {{ data.item.cash_statuses.name }}
                </b-badge>
              </template>
              <template #cell(frontdesks)="data">
                <b-media vertical-align="center" v-if="data.item.frontdesks">
                  <template #aside>
                    <b-avatar size="32" :src="data.item.frontdesks.avatar" />
                  </template>
                  <b-link :to="{
                    name: 'admin-frontdesks-view',
                    params: { id: data.item.frontdesks.id },
                  }">
                    <span class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                      <span>
                        {{ data.item.frontdesks.name }}
                      </span>
                      <span>
                        {{ data.item.frontdesks.lastname }}
                      </span>
                    </span>
                  </b-link>
                </b-media>
              </template>
            </b-table>
          </b-card>
        </template>
        <template #cell(facilities)="">
          <b-media vertical-align="center" v-if="facilityId">
            <template #aside>
              <b-avatar size="32" :src="facilityId.avatar" />
            </template>
            <b-link :to="{
              name: 'admin-facilities-view',
              params: { id: facilityId.id },
            }">
              <span class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ facilityId.name }}
                </span>
                <span>
                  {{ facilityId.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(cash_statuses)="data">
          <b-badge pill :variant="data.item.cash_statuses_id === 1 ? 'success' : 'danger'">
            {{ data.item.cash_statuses.name }}
          </b-badge>
        </template>
        <template #cell(frontdesks)="data">
          <b-media vertical-align="center" v-if="data.item.frontdesks">
            <template #aside>
              <b-avatar size="32" :src="data.item.frontdesks.avatar" />
            </template>
            <b-link :to="{
              name: 'admin-frontdesks-view',
              params: { id: data.item.frontdesks.id },
            }">
              <span class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.frontdesks.name }}
                </span>
                <span>
                  {{ data.item.frontdesks.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(closing_statuses)="data">
          <div v-if="data.item.closing_statuses.length > 0" class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.closing_statuses[0].name
            }}</span>
          </div>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BMedia,
  BLink,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";

import axiosCV from "@/core/services/api/cash-account/index";

import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import HistoryFilter from "./HistoryFilter.vue";
import CardCash from "../CardCash";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BMedia,
    BLink,
    BFormCheckbox,
    HistoryFilter,
    CardCash,

    ToastNotification,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "show_details" },
        { key: "cash_statuses", label: "Cash Status" },
        { key: "facilities", label: "Facility", sortable: true },

        { key: "frontdesks", label: "Frontdesk", sortable: true },
        // A regular column
        { key: "current_balance", label: "current balance" },
        { key: "current_descriptive" },
        { key: "income" },
        { key: "opening" },
        { key: "closure" },
        { key: "closing_statuses", label: "Closing Status" },
      ],
      items: null,
      loading: false,
      fieldsCash: [
        { key: "name", label: "Name" },
        { key: "event.movement_types.name", label: "Movement" },
        { key: "value", label: "Amount", sortable: true },
      ],
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      ailmentFilter: null,
      facilityId: null,
    };
  },
  mounted() {
    this.getAilment();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getAilment();
      } else {
        this.searchFilter(this.ailmentFilter);
      }
    },
  },
  methods: {
    getAilment() {
      axiosCV.cashGlobalHistory(this.$route.params.id, this.perPage).then(
        ({
          registro: {
            caches: { data, total, current_page, per_page, ...res },
            facility,
          },
        }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.facilityId = facility;
          this.currentPage.page = current_page;
        }
      );
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV.cashGlobalHistoryPaginated(this.$route.params.id, this.perPage, page).then(
          ({
            registro: {
              caches: { data, total, current_page, per_page, ...res },
              facility,
            },
          }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.facilityId = facility;
            this.currentPage.page = current_page;
          }
        );
      } else {
        axiosCV.cashGlobalHistoryPaginated(this.$route.params.id, this.perPage, page, this.ailmentFilter).then(
          ({
            registro: {
              caches: { data, total, current_page, per_page, ...res },
              facility,
            },
          }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.facilityId = facility;
            this.currentPage.page = current_page;
          }
        );
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.ailmentFilter = value;
        axiosCV
          .ailmentFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getAilment();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    getColor(id) {
      if (id === 1) return "success";
      if (id === 2) return "danger";
      if (id === 3) return "success";
      if (id === 4) return "danger";
      if (id === 5) return "secondary";
      return "success";
    },
    getIcon(id) {
      if (id === 1 || id === 3) return "fas fa-arrow-up";
      if (id === 2 || id === 4) return "fas fa-arrow-down";
      return "fas fa-arrow-down";
    },
  },
};
</script>

<style></style>
