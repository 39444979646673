var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('filter', 'ailments'))?_c('HistoryFilter',{attrs:{"loading":_vm.loading},on:{"searchFilter":_vm.searchFilter,"filterPage":_vm.filterPage}}):_vm._e(),_c('b-card',[_c('TitleTable',{attrs:{"titleTable":"History","iconTable":"GitBranchIcon"}}),_c('div',{staticClass:"m-1"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('treeselect',{staticClass:"w-25",attrs:{"options":_vm.perPageOptions,"clearable":true},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('GoBack',{staticClass:"mr-2"})],1)])],1)],1),_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.items,"fields":_vm.fields,"hover":"","bordered":"","responsive":"","primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('CardCash',{attrs:{"CashStats":{
            income: row.item.income,
            current_balance: row.item.current_balance,
            current_descriptive: row.item.current_descriptive,
          }}}),_c('b-table',{staticClass:"mt-2",attrs:{"small":"","bordered":"","responsive":"sm","show-empty":"","empty-text":"No matching records found","fields":_vm.fields,"items":row.item.cashes},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-table',{staticClass:"mt-2",attrs:{"small":"","bordered":"","responsive":"sm","show-empty":"","empty-text":"No matching records found","fields":_vm.fieldsCash,"items":row.item.movements},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                    name: 'recipe-patient',
                    params: { id: data.item.movementable_id },
                  }}},[_vm._v(_vm._s(data.item.event.name))])]}},{key:"cell(value)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":_vm.getColor(data.item.event.movement_types_id)}},[_c('i',{staticClass:"mr-2 text-white",class:_vm.getIcon(data.item.event.movement_types_id)}),_vm._v(" "+_vm._s("$ " + data.value)+" ")])]}}],null,true)})]}},{key:"cell(cash_statuses)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":data.item.cash_statuses_id === 1 ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(data.item.cash_statuses.name)+" ")])]}},{key:"cell(frontdesks)",fn:function(data){return [(data.item.frontdesks)?_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
                  name: 'admin-frontdesks-view',
                  params: { id: data.item.frontdesks.id },
                }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.lastname)+" ")])])])],1):_vm._e()]}}],null,true)})],1)]}},{key:"cell(facilities)",fn:function(){return [(_vm.facilityId)?_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":_vm.facilityId.avatar}})]},proxy:true}],null,false,14536359)},[_c('b-link',{attrs:{"to":{
            name: 'admin-facilities-view',
            params: { id: _vm.facilityId.id },
          }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(_vm.facilityId.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.facilityId.lastname)+" ")])])])],1):_vm._e()]},proxy:true},{key:"cell(cash_statuses)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":data.item.cash_statuses_id === 1 ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(data.item.cash_statuses.name)+" ")])]}},{key:"cell(frontdesks)",fn:function(data){return [(data.item.frontdesks)?_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
            name: 'admin-frontdesks-view',
            params: { id: data.item.frontdesks.id },
          }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.lastname)+" ")])])])],1):_vm._e()]}},{key:"cell(closing_statuses)",fn:function(data){return [(data.item.closing_statuses.length > 0)?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.closing_statuses[0].name))])]):_vm._e()]}}])}),_c('PaginationTable',{attrs:{"dataMeta":_vm.dataMeta,"totalUsers":_vm.totalUsers,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"page-changed":_vm.pageChanged}})],1),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }